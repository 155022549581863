import React from 'react';
import ReactDOM from 'react-dom';
import './styles/scss/start.scss';

import App from './containers/App';
import registerServiceWorker from './registerServiceWorker';
import { StateProvider } from "./core/store/store";

import strapi from './config/strapi';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KRKPBQC'
}

if(window.location.origin === strapi.URL){
    TagManager.initialize(tagManagerArgs)
}else{
 //   console.log("NO ES PROD")
}

const app = (
  <StateProvider>
    <App />
  </StateProvider>
)

//ReactDOM.hydrate(app, document.getElementById('root'));
ReactDOM.render(app, document.getElementById('root'));


registerServiceWorker();
