/**
 *
 * ProductPage
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory }  from 'react-router-dom';
import { connect, useSelector } from "react-redux";

import Table from '../../components/wrappers/Table-flex';
import ListElements from "../../components/wrappers/ListElements";
import Header from "../../components/navigation/components/Header"
import { Container, Row, Col } from 'react-bootstrap';

import strapi from '../../config/strapi';

let ProductPage = (props) => {

  const store = useSelector(store => store);
  const history = useHistory()
  const { match: { params } } = props;
  const [filter, setFilter] = useState([]);
  const [origFilter, setOrigFilter] = useState([]);
  const [ediciones, setEdiciones] = useState([])
  let contentType = params.contentType === "users" ? "cocreadores" : params.contentType;
  let lasnotas = []
  let edicionesList = ediciones
  let notasList = store.data[contentType]
  let listaItems = [];

  // class ProductPage extends React.Component {
  //state = { notas: [], filter: [], ediciones :[] };

  // async componentDidMount() {
  //   this.props.requestApiData(this.props.match.params);    
  // }

  const onClick = (id) => {
    history.push(`/${params.contentType}/${id}`);
  }

  const onclick2 = (body) => {
    console.log("body", body);

    // let cocreadores = body.galeria.map(x => x._id);
    //  let tieneCocreadores = cocreadores.length > 0 ? true : false;

    // console.log(body)
    let newData = {
      cuerpo2: [
        {
          "__component": "cocreador.cocreador",
          "aporte": body.detacado_datos,
          "columnas": body.detacado_datos && body.detacado_datos !== "" ? 2 : 1
        },

      ]
    }

    // let cocreadores = body.cocreadores.map(x => x._id);
    // let tieneCocreadores = cocreadores.length > 0 ? true : false;
    // console.log(cocreadores);
    // console.log(tieneCocreadores);


    // console.log(body)
    // let cocreadores2 = [];

    // cocreadores.map(x => {
    //   let ele = {
    //     "__component": "cocreador.cocreador",
    //       "user" : x
    //   }
    //     cocreadores2.push(ele);

    // })
  
    // console.log("LOS 2", cocreadores2);

    // let newData = {
    //   cocreadores2: cocreadores2
    // }
    



    const requestURL = params.id_url === 'create' ? strapi.URL + "/" + contentType : strapi.URL + "/" + contentType + "/" + body.id
    // const method = params.id_url=== 'create' ? 'POST' : 'PUT';

    console.log(requestURL);
    console.log("body", newData);

    // if(tieneCocreadores){
    //   return request(requestURL, {method, body: newData, headers: {}  })
    //     .then(resp => {
    //     console.log(resp);
    //     alert("OK")
    //     })
    //     .catch(err => {
    //       console.log('err', err.response);
    //       //  TODO: Handle errors
    //     }).finally(() => {
    //       // TODO: make sure the redirection happens when all the files have been updated
    //     });
    // }else{
    //   alert("NO HACE FALTA")
    // }

  
    //alert(id);
    // history.push(`/${params.contentType}/${id}`);
  }

  

  useEffect(() => {
    if(filter.length === 0) {
      let ediciones = store.data.ediciones;
      let edicionesid = ediciones.map(i => i.id);
      let noEdicion = [null]
      let noEdicion2 = [{id:null, titulo: "sin edicion"}]
      setFilter([...edicionesid, ...noEdicion])
      setOrigFilter([...edicionesid, ...noEdicion])
      setEdiciones([...ediciones, ...noEdicion2])
    }

  }, [store, filter])



  const handleToUpdate = (someArg) =>{
    !handleCheck(someArg) ?
    setFilter([...filter, ...someArg])
   : 
    setFilter(filter.filter(item => item===someArg))
  }
 
  const handleCheck = (val) =>{
    return filter.some(item => val === item);
  }
  
  const handleReset = () =>{
    setFilter([...filter, ...origFilter])
  }

  const returnNotas = (ele, filter) =>{
    let newArray = [];
    ele.filter(function(item) {
      if(item.edicion){
        if (filter.some( r=> item.edicion._id.includes(r))) {
            var existingIds = newArray.map((obj) => obj);
            if (!existingIds.includes(item)) {
                newArray.push(item);
            } 
        return true
        }
      }else if(item.edicion === null){
        if(filter.some(r => item.edicion ===r)){
        }
      }
      return true
    });
    
    if (params.contentType === 'notas'){
      return newArray
    }else{
      return ele
    }
  }

  lasnotas = notasList ? returnNotas(notasList, filter): notasList;

    
  switch (contentType){
    case "notas":
    listaItems = ['portada','titulo', 'esblog', 'esproyecto', 'publicada'];
    break
    case "ediciones":
    listaItems = ['imagen','titulo', 'numero', ""]
    break
    case "cocreadores":
    listaItems = ['imagen','nombre', 'titulo', ""]
    break
    case "hashtags":
    listaItems = ['hashtag', "activo"]
    break
    case "categorias":
    listaItems = ['nombre', ""]
    break
    case "secciones":
    listaItems = ['Nombre', "Orden"]
    break
    default:
  }
  
  return (
    <div className="homepage-container">
      <Header titulo={contentType} back="/editor"/>

      <Container>
        <Row >
          <Col md={12} className="productPageLink text-right">
            <Link to={`/${params.contentType}/create`}>{`Crear ${contentType}`}</Link>
          </Col>
        </Row>
            
          {edicionesList && contentType === "notas" ?
          <ListElements 
                element="categorias" 
                type="lista"
                lista={edicionesList}
                hasAll={true}
                active={filter}
                handleReset={handleReset.bind(this)} 
                handleToUpdate={handleToUpdate.bind(this)} 
          />
          :""}

        <Table
            contentType={contentType}
            data={lasnotas}
            headers={listaItems}
            onClick={onClick}
            onClickAutomatico={onclick2}
          />
      </Container>
    </div>
  );
}

ProductPage.defaultProps = {};

ProductPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default connect()(ProductPage);
