import React, {Suspense} from 'react';
import Loader from '../../components/navigation/components/Loader';

// NAVIGATION
const Head = React.lazy(() => import('../../components/navigation/components/Head'));
// const Header = React.lazy(() => import('../../components/navigation/components/Header'));
// const Footer = React.lazy(() => import('../../components/navigation/Footer'));

let HomePage = (props) => {
  return (
    <div className={`homepage-container empty`}>
      <Suspense fallback={<Loader></Loader>}><Head page="home" /></Suspense>  
      <main id="main">
        <Loader type="page"></Loader>
        <h3>13.12</h3>
      </main>    
    </div>
  )
}

export default HomePage