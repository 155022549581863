/**
 * Loader
 *
 */

import React from 'react';
import LoaderImage from '../../../../assets/LoaderImage/index.js';
// import hashes from "./hashes.js"

let Loader = (props) => {
    const {type} = props

    // console.log("TYPE", type);
    return (
       <div className={`loading-container ${type === "page" ? "full" :''}`}>
        
        {type === "page" ?
         <React.Fragment>
          <div className="eclipse"></div>
          <div className="loading"></div>
          {/* <div className="lista">
          {
            hashes.hashtags.map((e, key) =>{
                return <span key={key}>{e}</span>
              })
            }
        </div> */}
       </React.Fragment>
      
        :
        <div className="loading">
          <LoaderImage />
        </div>

        }
        
       </div>
    );
  }
export default Loader

