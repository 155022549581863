// store.js
import React, {createContext, useReducer} from 'react';
import { mergeArrays } from '../helpers/helpers';

const INITIAL_STATE = {
  header: [],
  footer: [],
  notas_destacadas: [],
  notas_seccion: [],
  secciones: [],
  notas: [],
  cocreadores: [],
  ediciones: [],
  categorias: [],
  cocreadores_destacados: [],
  equipo_magma: [],
  seccion__zona_de_interaccion: [],
  seccion__cocreadores: [],
  section__home: [],
  seccion__proyecto: [],
  seccion__unete: [],
  seccion__portal: [],
  seccion__nosotros: [],
  seccion__resiste: [],
  
}

const store = createContext(INITIAL_STATE);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    //console.log(action);
    const data  = action.payload;
    switch(action.type) {
      case 'SECTION_HOME':
        return { ...state, 
          section__home: data.attributes.seccion.filter(s => s.activo),
          header: data.attributes.seccion ? data.attributes.seccion.filter(s => s.menu).sort((a, b) => a.orden -b.orden) : [],
          footer: data.attributes.seccion ? data.attributes.seccion.filter(s => s.menu).sort((a, b) => a.orden -b.orden) : [],
        };
      case 'SECCIONES':
        return { ...state, 
          secciones: data,
        };
      case 'EDICIONES':
        return { ...state, 
          ediciones: data,
      };
      case 'CATEGORIAS':
        return { ...state, 
          categorias: data,
      };

      case 'NOTAS_DESTACADAS':
        return { ...state, 
          notas_destacadas: data,
          notas: mergeArrays(state.notas, data),
        }; 

      case 'NOTAS_BY_SECCION':  
        return { ...state, 
          notas_seccion: data,
          notas: mergeArrays(state.notas, data),
        };
      case 'NOTAS':
        return { ...state, 
          notas: mergeArrays(state.notas, data),
        };
      case 'NOTAS_CATEGORIA':
        return { ...state, 
          notas: mergeArrays(state.notas, data),
      };
      case 'SECTION_ZONA_DE_INTERACCION':
        return { ...state, 
          seccion__zona_de_interaccion: data,
        };
      case 'USERS':
          return { ...state, 
            cocreadores: data.filter(cocre => cocre.role?.id === 3),
            cocreadores_destacados: data.filter(cocre => cocre.destacado && cocre.role?.id === 3),
            equipo_magma: data.filter(coc => coc.role?.id === 4),
          };    
      case 'SECTION_COCREADORES':
        return { ...state, 
          seccion__cocreadores: data,
        }; 
      case 'SECTION_PROYECTO':
        return { ...state, 
          seccion__proyecto: data,
        }; 
      case 'SECTION_UNETE':
        return { ...state, 
          seccion__unete: data,
        }; 
      case 'SECTION_PORTAL':
        return { ...state, 
          seccion__portal: data,
        }; 
      case 'SECTION_NOSOTROS':
        return { ...state, 
          seccion__nosotros: data,
        }; 
      case 'SECTION_RESISTE':
        return { ...state, 
          seccion__resiste: data,
        };   
        default:
        throw new Error();
    };
  }, INITIAL_STATE);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }