import strapi from '../../config/strapi';
import axios from 'axios';

export const REQUEST_API_DATA = "REQUEST_API_DATA";
export const RECEIVE_API_DATA = "RECEIVE_API_DATA";

// export const RECEIVE_API_DATA = "RECEIVE_API_DATA"; 
export const RECEIVE_PAGE_DATA = "RECEIVE_PAGE_DATA"; 
export const RECEIVE_NOTA_DATA = "RECEIVE_NOTA_DATA"; 
export const REQUEST_NOTA_DATA = "REQUEST_NOTA_DATA"; 
export const LOADED = "LOADED"; 
export const GET_PAGE = "GET_PAGE";
export const GET_HISTORY = "GET_HISTORY";
export const SET_INTERACTIVO = "SET_INTERACTIVO";
export const SET_SECCION = "SET_SECCION";


// export const requestApiData = (param) => ({type: REQUEST_API_DATA, param});
// export const receiveApiData = data => (createPromiseAction({type: RECEIVE_API_DATA, data}));

export const requestApiData = (param) => ({type: REQUEST_API_DATA, param});
export const receiveApiData = data => ({type: RECEIVE_API_DATA, data});


export const receivePageData = data => ({type: RECEIVE_PAGE_DATA, data});
//export const receiveNotaData = (data) => ({type: RECEIVE_NOTA_DATA, data});
export const receiveNotaData = (data) => ({type: RECEIVE_NOTA_DATA, data});
export const requestNotaData = (data) => ({type: REQUEST_NOTA_DATA, data});


export const loadedALL = (data) => ({type: LOADED, data});

export const getPage = (data) => ({type: GET_PAGE, data});
export const getHistory = (data) => ({type: GET_HISTORY, data});


export const fetchMoreCats = () => dispatch =>{
    dispatch({type: "FETCH_MORE_CATS"})
}

export const setInteractivoActive = (data) => ({type: SET_INTERACTIVO , payload: data})
export const setSeccionActive = (data) => ({type: SET_SECCION , payload: data})

// const fetchAllError = () =>{
//     return {type: "RECEIVE_API_DATA_ERROR"}
// }

const fetchAllSuccess = (cat) => {
    console.log(cat);
    return {type: "RECEIVE_API_DATA", payload: cat}
}

const urls = [
    {tipo: "hashtags", url:strapi.hashtags.all},
    {tipo: "notas", url:strapi.notas.all}, 
    {tipo: "ediciones", url:strapi.ediciones.all},  
    {tipo: "cocreadores", url: strapi.cocreadores.all},    
    {tipo: "categorias", url:strapi.categorias.all},   
    {tipo: "secciones", url:strapi.secciones.all},    
    {tipo: "nosotros", url:strapi.secciones.nosotros},    
    {tipo: "proyecto", url:strapi.secciones.proyecto},    
    {tipo: "cocreadores_home", url:strapi.secciones.cocreadores_home},    
    {tipo: "resiste", url:strapi.secciones.resiste},    
    {tipo: "unete", url:strapi.secciones.unete   },
    {tipo: "zona", url:strapi.secciones.zona   },
    {tipo: "home", url:strapi.secciones.home   } 
]


// const fetchSections = (cat) => {
//     //console.log(cat);
//     const action = {type: "RECEIVE_API_DATA", payload: cat}

//     return {undefined, action}
// }

const Actions = {
    async getSectionHome()  {
        const url = strapi.secciones.home;
        let data = { type: 'SECTION_HOME', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })
  
        return data;
    },

    async getSecciones()  {
        const url = strapi.secciones.all;
        let data = { type: 'SECCIONES', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        }) 
        return data;
    },

    async getNotasDestacadas()  {
        const url = strapi.notas.destacadas;
        let data = { type: 'NOTAS_DESTACADAS', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        }) 
        return data;
    },

    async getNotasBySeccion(id)  {
        const url = strapi.notas.seccion + id;
        let data = { type: 'NOTAS_BY_SECCION', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getNotaByUrl(id)  {
        const url = strapi.notas.one + id;
        let data = { type: 'NOTAS', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getNotaByEdicion(id)  {
      const url = strapi.notas.edicion + id;
      let data = { type: 'NOTAS', payload: [] }
      await axios.get(url)
      .then(res => {
        data.payload = res.data.data;
        return data;
      })  
      console.log(data)
      return data;
    },

    async getNotaByCocreador(id)  {
      const url = strapi.notas.cocreador + id;
      let data = { type: 'NOTAS', payload: [] }
      await axios.get(url)
      .then(res => {
        data.payload = res.data.data;
        return data;
      })  
      console.log(data)
      return data;
  },



    async getNotaByCategoria(query)  {
      const url = strapi.notas + query;
      let data = { type: 'NOTAS_CATEGORIA', payload: [] }
      await axios.get(url)
      .then(res => {
        data.payload = res.data.data;
        return data;
      })  
      return data;
    },
  
    async getNotaSearch(text)  {
      const url = strapi.notas.search + text;
      let data = { type: 'NOTAS', payload: [] }
      await axios.get(url)
      .then(res => {
        data.payload = res.data.data;
        return data;
      })  
      return data;
    },
  

    


    async getZonaDeInteraccion()  {
        const url = strapi.secciones.zona ;
        let data = { type: 'SECTION_ZONA_DE_INTERACCION', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getUsers()  {
        const url = strapi.cocreadores.all ;
        let data = { type: 'USERS', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data;
          return data;
        })  
        return data;
    },

    async getEdiciones()  {
        const url = strapi.ediciones.all ;
        let data = { type: 'EDICIONES', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getCategorias()  {
        const url = strapi.categorias.all ;
        let data = { type: 'CATEGORIAS', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getSectionCocreadores()  {
        const url = strapi.secciones.cocreadores_home ;
        let data = { type: 'SECTION_COCREADORES', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getSectionProyecto()  {
        const url = strapi.secciones.proyecto ;
        let data = { type: 'SECTION_PROYECTO', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getSectionUnete()  {
        const url = strapi.secciones.unete ;
        let data = { type: 'SECTION_UNETE', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getSectionPortal()  {
        const url = strapi.secciones.resiste ;
        let data = { type: 'SECTION_PORTAL', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    async getSeccionNosotros()  {
        const url = strapi.secciones.nosotros ;
        let data = { type: 'SECTION_NOSOTROS', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },
        
    async getSeccionResiste()  {
        const url = strapi.secciones.resiste ;
        let data = { type: 'SECTION_RESISTE', payload: [] }
        await axios.get(url)
        .then(res => {
          data.payload = res.data.data;
          return data;
        })  
        return data;
    },

    // addNotas(notas){
    //     let data = { type: 'NOTAS', payload: notas }
    //     return data;
    // }


}


export {
    Actions
}


// async function getMYcontent(url) {
//     await axios({
//         method: 'get',
//         url: url.url,
//       })
//     .then(resp =>  resp.json())
//     .catch(
//         function (error) {
//           console.log('Show error notification!')
//           return Promise.reject(error)
//         }
//       )
//   }


export const fetchAll = () => dispatch => {
    let data = {}
    const headers = {headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json',
    }}

    // Promise.all(urls.map(url =>
    //     axios.get(url.url, headers)
    //    // fetch(url.url)
    //     .then(resp => resp.json())
    //     .catch(
    //         function (error) {
    //           console.log('Show error notification!')
    //           return Promise.reject(error)
    //         }
    // )))

    // urls.forEach(function (url) {
    //     const myUrl = url.url;  
    //     const newData = []
    //     let myReq = axios.get(myUrl, headers).then(resp => {newData = resp.json()});
    //     promises.push(myReq);
    //     debugger;
    //     return newData;
    // });
    
    // Promise.all(promises)
    // .then(texts => {
    //     urls.map((url, i) => {
    //         data = {...data, [url.tipo] : texts[i]}
    //         debugger;
    //         return data
    //     })
    //     return data;
    // })
    // .then( cat => {
    //     dispatch(fetchAllSuccess(cat))
    // })
    // .catch( err => dispatch(fetchAllError()))

    function getAllData(URLs){
        return Promise.all(URLs.map(fetchData));
    }
      
    function fetchData(URL, retries = 0) {
    // return axios
    // const retryCodes = [408, 500, 502, 503, 504, 522, 524]

    //     .get(URL.url, headers)
    //     .then(resp =>  resp.json())
    //     .catch(function(error) {
    //     return { success: false };
    //     });
    return fetch(URL.url, headers)
        .then(resp =>  {
            const data = resp.json()          
            if (resp.ok) return data;

            // if (retries > 0 && retryCodes.includes(resp.status)) {
            //     return fetchData(URL.url, retries - 1)
            // } else {
            //     throw new Error(resp)
            // }
                
        })
        .catch(function(error) {
            return false;
            //return { error: error, success: false };
        });
    }
      
    getAllData(urls)
    .then(resp => {
        console.log(resp)
        urls.map((url, i) => {            
            if(resp[i]){
                data = {...data, [url.tipo] : resp[i]}
                return data
            }
            return false
          })
    })
    .then(re => {
        dispatch(fetchAllSuccess(data));
    })
    .catch( e=> {
        console.log(e)
    })
}