/**
 *
 * Detalle Page
 *
 */

import React, { Suspense, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// NAVIGATION
import Loader from '../../components/navigation/components/Loader';

//config and utils
import layout from './layout.json';


 // NAVIGATION
 const Head = React.lazy(() => import('../../components/navigation/components/Head'));
 const Header = React.lazy(() => import('../../components/navigation/components/Header'));
 const Footer = React.lazy(() => import('../../components/navigation/components/Footer'));

// Sections
 const Nota = React.lazy(() => import('../../components/sections/Nota'));
 const Edicion = React.lazy(() => import("../../components/sections/Edicion"));
 const Cocreador = React.lazy(() => import('../../components/sections/Cocreador'));
 const Hashtag = React.lazy(() => import('../../components/sections/Hashtag'));
 const Categoria = React.lazy(() => import('../../components/sections/Categoria'));
 const Seccion = React.lazy(() => import('../../components/sections/Seccion'));

let DetallePage = (props) => {
  const [isEdit] = useState(false);
  const { match: { params } } = props;
  const [pageParams, setPageParams] = useState(params);
  const [isDark] = useState(true);
  const [modifiedData, setModifiedData] = useState();
  const history = useHistory();

  const handleChange = (e, data, isrich ) =>{
    console.log(e.target);
    console.log(isrich);

    let name = e.target ? e.target.name : e.name;
    let value = e.target ? e.target.value : e.value;

    if(isrich){
      name = e;
      value = data
    }

    //console.log(name);
    setModifiedData({ ...modifiedData, [name]: value});
    console.log(modifiedData);
  }

  useEffect(() => {
    return history.listen((location) => { 
      const newParams = {
        contentType: location.pathname.split('/')[1],
        id_url: location.pathname.split('/')[2]
      }

      console.log(newParams.id_url);
      setPageParams(newParams)
   }) 
  }, [history, props])

  const content = []//modifiedData;

  const [contentData, setContentData] = useState();
  const titulo = layout[pageParams.contentType].titulo;
  let title;

  const getChildData = (data) => {
    setContentData(data);
  }

  if (contentData) {
    window.scrollTo(0, 0);
    if (contentData.attributes) {
      title = contentData.attributes[titulo];
    } else {
      title = contentData[titulo];
    }

  }

  const handleChangeList = (listType, lista, type) =>{
    const tipo = getListType(listType)    
    if(tipo === "notas_relacionadas"){
      setModifiedData({ ...modifiedData, notas: [],notas_relacionadas: lista });
    }else if(tipo === "seccion" || tipo === "edicion"){
      if(type === "BORRAR"){
        lista = null
      }
      setModifiedData({ ...modifiedData, [tipo]: lista });
    }else{
      setModifiedData({ ...modifiedData, [tipo]: lista });
    }
    
    console.log(tipo, lista);
    console.log("CHANGED")
  }

  const getListType = (listType) =>{
    let tipo;
    switch (pageParams.contentType){
      case "cocreadores":
      tipo = listType === "notas" ? "notas_cocreadores" : listType
      break
      default:
      tipo = listType
    }

    return tipo;
  }

  const renderElements = () =>{
    const type = pageParams.contentType === "equipo" ? "cocreadores" : pageParams.contentType;
      console.log(pageParams)


    switch(type){
      case "cocreadores":
        return(
          <Cocreador 
            type="full" 
            id={pageParams.id_url}
            isEdit={isEdit} 
            updateParent={getChildData}
          />
          )
      case "ediciones":
          return ( 
            <Edicion 
              type="full" 
              id={pageParams.id_url} 
              isEdit={isEdit} 
              updateParent={getChildData}
          />)
      case "notas":
              return (
              <Nota
                type="full" 
                id={pageParams.id_url}
                isEdit={isEdit} 
                updateParent={getChildData}
              />
            )
      case "hashtags":
          return ( 
          <Hashtag 
            type="full" 
            content={content} 
            isEdit={isEdit} 
            onChange={handleChange} 
            onChangeList={handleChangeList}
        />
      )
      case "categorias":
          return ( 
          <Categoria 
            type="full" 
            content={content} 
            isEdit={isEdit} 
            onChange={handleChange} 
            onChangeList={handleChangeList}
          />
      )
      case "secciones":
      return ( 
        <Seccion 
          type="full" 
          content={content} 
          isEdit={isEdit} 
          onChange={handleChange} 
          onChangeList={handleChangeList}
        />
      )
      default:
      return null
    }
  }

  const renderFooter = () =>{
    if(!isEdit && pageParams.contentType !== "hashtags" && pageParams.contentType !== "categorias" && pageParams.contentType !== "secciones"){
      return <Footer />
    }
  }

  // return false
  return (
  <div className="detalle-page-wrapper">
    {/* {saving ?renderSaving():""} */}
    <Suspense fallback={<Loader type="page"></Loader>}>
    
    <Head page={pageParams.contentType} content={contentData} />
        <React.Fragment>
          <Header 
            titulo={title}
            isEdit={isEdit} 
            isDark={isDark}
          />
      
          <main className="first-section interna">
              {renderElements()}
          </main>

          {renderFooter()}
        </React.Fragment>
    </Suspense>
  </div>
  );
}

export default DetallePage;

