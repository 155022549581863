/**
 *
 * ConnectPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

// Utils
import auth from '../../utils/auth';
import request from '../../utils/request';
import strapi from '../../config/strapi';
import { useHistory } from 'react-router-dom';

function ConnectPage(props) {
  // We only use this lifecycle because it's only mounted once and the saga already handle
  // the redirections depending on the API response
  const { match: { params: { provider } },location: { search },} = this.props;
  const requestURL = strapi.URL + `/auth/${provider}/callback${search}`;
  const history = useHistory();

  // NOTE: YOU can delete this container and do the logic in the HomePage formContainer
  // This implementation was just made for the sake of the example and to silmplify the logic
  request(requestURL, { method: 'GET' })
      .then(response => {
        auth.setToken(response.jwt, true);
        auth.setUserInfo(response.user, true);
        redirectUser('/');
      })
      .catch(err => {
        console.log(err.response.payload);
        redirectUser('/auth/login');
      });
 
  const redirectUser = path => {
    history.push(path);
  };

  return (
    <div>
      <h1>Retrieving your token and checking its validity</h1>
    </div>
  );
}

ConnectPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ConnectPage;
