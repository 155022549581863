/**
 *
 *
 * App
 */

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import  ReactDOM from 'react-dom';

// Components
import AuthPage from '../AuthPage';
import ConnectPage from '../ConnectPage';
import HomePage from '../HomePage';
import EmptyHomePage from '../EmptyHomePage';
import NotFoundPage from '../NotFoundPage';
import EditPage from '../EditPage';
import EditorPageHome from '../EditorPage';
import DetallePage from "../DetallePage";
import SeccionesPage from "../SeccionesPage";

// This component ios HoC that prevents the user from accessing a route if he's not logged in
import PrivateRoute from '../PrivateRoute';

// import { MyPureIframe } from '../../components/elements/PureIframe';


// const Portal = () => {
//   return ReactDOM.createPortal(
//     <div className="algo">
//     <MyPureIframe src={'https://interactivo-portal.ue.r.appspot.com/'} width={100} height={100} id={'inter-iframe'}/>
//     </div>,
//     document.querySelector('#interactivo .interactivo') || document.body
//   )
// }


const App = () =>{

  const isEmpty = false;

  if(isEmpty){
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route path="/" exact component={EmptyHomePage} />
          </Switch>
        </div>
      </Router>
    )
  }

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/auth/:authType/:id?" component={AuthPage} />
          <Route path="/" exact component={HomePage} />
          {/* <PrivateRoute exact path="/home-edicion" component={HomePageLogged} /> */}
          <PrivateRoute exact path="/edit/:contentType" component={EditPage} />
          {/* <PrivateRoute exact path="/editar/:contentType/:id" component={EditPage} /> */}
          <PrivateRoute exact path="/editor" component={EditorPageHome} />
          {/* <PrivateRoute exact path="/ediciones" component={EdicionPage} /> */}
          {/* <Route path="/notas/:id_url" component={NotaPage} /> */}
          
          <Route exact path="/:contentType/:id_url" component={DetallePage} />

          <Route exact path="/preview/:contentType/:id_url" component={DetallePage} />

          <Route exact path="/portal" component={SeccionesPage} />
          <Route exact path="/zona-de-interaccion" component={SeccionesPage} />
          <Route exact path="/unete" component={SeccionesPage} />
          <Route exact path="/resiste" component={SeccionesPage} />
          <Route exact path="/proyecto-magma" component={SeccionesPage} />
          <Route exact path="/cocreadores" component={SeccionesPage} />
          <Route exact path="/buscar" component={SeccionesPage} />


          {/* <Route path="/cocreadores/:id_url" component={CocreadorPage} />
          <Route path="/ediciones/:id_url" component={EdicionPage} /> */}
          <Route exact path="/connect/:provider" component={ConnectPage} />
          <Route path="" component={NotFoundPage} />
        </Switch>
          {/* <Portal></Portal> */}
      </div>
    </Router>
  );
}

export default App;
