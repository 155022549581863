//let sourceData = 'https://magma-portal-cms.ue.r.appspot.com';
const env = window.location.hostname === "localhost" ? "dev" : "prod";
const base = process.env.REACT_APP_API_URL || 'https://magma-portal-cms.ue.r.appspot.com';
const baseShare = process.env.REACT_APP_SHARE_URL ||  'https://magma.la';
// const base = process.env.REACT_APP_API_URL || 'https://stage-dot-magma-portal-cms.ue.r.appspot.com';
// const baseShare = process.env.REACT_APP_SHARE_URL || 'https://stage-dot-magma-portal-cms.ue.r.appspot.com';

const sourceData = env === "-" ? 'http://localhost:1337': base;
const shareURL =  env === "--"  ? 'http://localhost:8000' : baseShare;

///"https://magma.la"

module.exports = {
  URL : sourceData,
  URL_SHARE : shareURL, // "http://localhost:1337"
  
  ediciones : {
    all: sourceData + "/api/ediciones?sort[0]=numero:DESC&filters[numero][$ne]=7.6", //REVUELTA == 7.6
  },
  hashtags:  {
    all: sourceData +'/hashtags/?populate=*',
  },
  cocreadores: {
    all: sourceData+'/api/users?sort[0]=destacado:DESC&sort[1]=orden:ASC&sort[2]=nombre:ASC&populate=*',
  },
  categorias: {
    all: sourceData+'/api/categorias/',
  },
  notas: {
    all: sourceData+"/api/notas/?esblog=true&publicada=true&_limit=1000000&_sort=orden:ASC,updatedAt:DESC",
    destacadas: sourceData+'/api/notas?filters[destacada][$eq]=true&filters[esblog][$eq]=true&sort[0]=orden_portal:ASC&sort[1]=updatedAt:DESC',
    seccion: sourceData+ '/api/notas?filters[destacada][$ne]=true&filters[esblog][$eq]=true&sort[0]=orden_portal:ASC&sort[1]=updatedAt:DESC&filters\[seccion\][id][$eq]=',
    edicion: sourceData+ '/api/notas?sort[0]=orden:ASC&sort[1]=updatedAt:DESC&filters\[edicion\][numero][$eq]=',
    cocreador: sourceData+ '/api/notas?sort[0]=orden_portal:ASC&sort[1]=orden:ASC&sort[2]=updatedAt:DESC&filters\[cocreadores\][user\][username][$eq]=',
    one: sourceData+"/api/notas/?sort[0]=orden:ASC&sort[1]=updatedAt:DESC&filters[id_url][$eq]=",
    search: sourceData+'/api/notas/?filters[esblog][$eq]=true&sort[0]=orden:ASC&sort[1]=updatedAt:DESC&search=',

  },  
  
  secciones: {
    all: sourceData+"/api/secciones/?sort[0]=orden:ASC&populate=*",
    nosotros: sourceData +'/api/nosotros/?populate=*',
    cocreadores_home: sourceData+"/api/cocreador/?populate=*",
    proyecto: sourceData+"/api/proyecto/?populate=*",
    resiste: sourceData+"/api/resiste/?populate=*",
    unete: sourceData + "/api/unete/?populate=*",
    zona: sourceData+"/api/zona-de-interaccion/?populate=*",
    home: sourceData+"/api/menu?populate=*"
  
  },
};
