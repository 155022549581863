/**
 *
 * Logo Full
 *
 */

import React from 'react';

let LoaderImage = () => {
  return (
    <svg className="loader-image" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" 
    viewBox="0 0 90 40">
      <g xmlns="http://www.w3.org/2000/svg">
        <path className="dot-1" d="M9.5 22.4c1-4 3-4 6-4s5 0 9.9 1c2 0 2.7 2.4 3 4 .5 2.5 0 2.3 0 6 0 1-2.9 8-6 8-4 0-8 1-11.9-3-3-4-1-12-1-12z"/>
        <path className="dot-2" d="M21.4 27.4c0 1.9-.1 3-2 3-.7 0-2 0-3-1-.8-.8-1-.8-1-2 0-1.9 1.1-4 3-4 1 0 1.7.5 2.2 1.3.5.7.8 1.7.8 2.7zM35.3 21.4c1-4 3-4 6-4h8c2 0 4.7 3.4 5 5 .5 2.5 0 2.3 0 6 0 1-1 6.2-4 7-4 1-8 1-13.9-1-4-2-1.1-13-1.1-13z"/>
        <path className="dot-3" d="M47.3 27.4c-1 2-1.1 2-3 2-.7 0-2 0-3-1-.8-.8-1-1.8-1-3 0-1.9 2.1-3 4-3 1 0 1.7.5 2.2 1.3.5.7 1.2 2.8.8 3.7zM59.2 23.4c1-3 4-3 7-3s3 0 8 1c2 1 2.7 3.4 3 5 .5 2.5 0 3.3 0 7 0 1-1.1 4-4 5-3 1-9 2-13.9-2-2.1-4-.1-13-.1-13z"/>
      </g>
    </svg>
  );
}

export default LoaderImage

