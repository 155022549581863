/**
 *
 * Buscar
 *
 */


import React, { Suspense, useContext } from 'react';
import {useState, useEffect} from 'react';

// NAVIGATION
import Loader from '../../components/navigation/components/Loader';

//config and utils

// import {animateScroll as scroll} from 'react-scroll'
import { useHistory, useLocation } from 'react-router-dom';
import seo from './seo.json';
import { get } from 'lodash';
// import { Col } from 'react-bootstrap';

import { store } from '../../core/store/store';
import { Actions } from '../../core/actions/actions';


// NAVIGATION
const Head = React.lazy(() => import('../../components/navigation/components/Head'));
const Header = React.lazy(() => import('../../components/navigation/components/Header'));
const Footer = React.lazy(() => import('../../components/navigation/components/Footer'));

// Sections
const Unete = React.lazy(() => import('../../components/sections/Unete'));
const Nosotros = React.lazy(() => import("../../components/sections/Nosotros"));
const Cocreador = React.lazy(() => import('../../components/sections/Cocreador'));
const Resiste = React.lazy(() => import('../../components/sections/Resiste'));
const Proyecto = React.lazy(() => import('../../components/sections/Proyecto'));
const ZonaInteraccion = React.lazy(() => import('../../components/sections/ZonaInteraccion'));
const Buscar = React.lazy(() => import( '../../components/sections/Buscar'));


let SeccionesPage = (props) => { 
  const [loaded, setLoaded] = useState(false);
  const [isEdit] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [type, setType] = useState(history.location.pathname || location.pathname);
  const [isDark] = useState(true);
  const seo_data = type ? get(seo, ['heads', `${type.substring(1)}`], []) : [];
  const titulo = seo_data.titulo;

  const globalState = useContext(store);
  const { section__home } = globalState.state; 
  const { dispatch } = globalState;

  useEffect(() => {    
    if(!loaded){
      const getSections = async () => {
        if(!section__home.length) {
          const secs = await Actions.getSectionHome();
          dispatch(secs);
        }
        setLoaded(true);
      }

      getSections();
    }
  },[ section__home, loaded, dispatch ]);


  useEffect(() => history.listen((location) => {
    if(type !== history.location.pathname){
      setType(history.location.pathname);
    }
  }), [location, history, type])

  const renderElements = () =>{  
    switch(type){
      case "/portal":
        return <Nosotros type="full"/>
      case "/resiste":
        return <Resiste type="full"/>
      case "/unete":
        return <Unete type="full"/>
      case "/proyecto-magma":
        return <Proyecto type="full"/>
      case "/cocreadores":
        return <Cocreador type="interna"/>
      case "/zona-de-interaccion":
        return <ZonaInteraccion type="full"/> 
      case "/buscar":
        return <Buscar type="interna"/>
      default:
      return null
    }
  }

  if(!loaded){
    return <Loader type="page"></Loader>
  }

  const headerHeight = document.querySelector("header") && document.querySelector("header").offsetHeight;
  const footerHeight = document.querySelector("footer") && document.querySelector("footer").offsetHeight;

  const minHeight = `calc(100vh - ${headerHeight + footerHeight + 20}px)`;

  return (
  <div className="secciones-page-wrapper">
    {!loaded ?<Loader type="page"/>:""}
    
    <Suspense fallback={<Loader type="page"></Loader>}>

    <Head page={titulo} content={seo_data} />
    <Header 
      pageProps={props} 
      titulo={titulo}
      isEdit={isEdit} 
      isDark={isDark}
    />
  
      <main className="first-section interna" style={{minHeight: minHeight}}>
        {renderElements()}
      </main>

      <Footer/>
      </Suspense>
  </div>
  );
}

export default SeccionesPage

