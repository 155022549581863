/**
 *
 * Editor PAge
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Header from "../../components/navigation/components/Header"
import Table from '../../components/wrappers/Table-flex';
import { Row, Col, Container } from 'react-bootstrap';

let EditorPage = () => {
  const history = useHistory();
  const listaItems = ["item", "", "", ""]
  const notas = [
    {item: "hashtags", id: "edit/hashtags"},
    {item: "ediciones", id: "edit/ediciones"},
    {item: "cocreadores", id: "edit/cocreadores"},
    {item: "notas", id: "edit/notas"},
    {item: "categorias", id: "edit/categorias"},
    {item: "secciones", id: "edit/secciones"}
  ]
    
  const onClick = (id) => {
   history.push(`/${id}`);
  }
 
  return (
    <div className="homepage-container">
      <Header titulo="Configuración"/>

      <Container className="container">
        <Row>
          <Col md={12} className="productPageLink">
            <p>Selecciona el item a editar de la lista</p>
          </Col>
        </Row>
        <Table
            data={notas}
            headers={listaItems}
            onClick={onClick}
          />
      </Container>
    </div>
  );
}

EditorPage.defaultProps = {};

EditorPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default EditorPage;

