/**
 *
 * TableRow
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const Empty = ({ colSpan }) => {
  return (
    <div className="Empty row">
      <div className="col-md-12">No hay contendo...</div>
    </div>
  );
}

Empty.defaultProps = {
  colSpan: 0,
};

Empty.propTypes = {
  colspan: PropTypes.number,
};

export default Empty;
