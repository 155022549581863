export const mergeArrays = (...arrays) => {
    let jointArray = []
  
    arrays.forEach(array => {
        jointArray = [...jointArray, ...array]
    })
    
    const uniqueArray = jointArray.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
    return uniqueArray
}

export const normalizeString = (str) => {
    return  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^A-Z0-9]/ig, "_").toLowerCase();
}