/**
 * Imagen
 *
 */

import React from 'react';
import { Link, useHistory } from 'react-router-dom';

let MyLink = (props) => {

  const {link, className, style, children, type} = props
  const history = useHistory();
  
  return (
    <Link title={link} className={className} style={style} 
      onClick={e=>{
        if(type === "back"){
          e.preventDefault();

          if(history.length > 2) {
            history.goBack();
          }else{
            history.push("/")
          }
        }
      }}
      to={link} 
      >
      {children}
    </Link>
  )

}

export default MyLink